import { Container, Flex, Heading, Text } from '@chakra-ui/react'
import useDesignSalabilities from '@modules/app/hooks/useDesignSalabilities'
import useProductStatuses from '@modules/app/hooks/useProductStatuses'
import useWhoAmI from '@modules/app/hooks/useWhoAmI'
import {
  isSubscriptionTypeProductInCart,
  isSubscriptionTypeProductTheOnlyProductTypeInCart,
} from '@modules/cart/utils'
import { getError, useCart } from '@modules/commercetools'
import { User } from '@modules/spoonflower/utils/graphql/generated/graphql'
import { useToast } from '@modules/ui'
import { useIsBrowser } from '@oriuminc/base'
import { PATHS } from 'constants/paths'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import useUserPreferences from '../../../app/hooks/useUserPreferences'
import { CartEmptyState } from '../CartEmptyState'
import { CartItemList } from '../CartItemList'
import { CartLoadingState } from '../CartLoadingState'
import { CartSummary } from '../CartSummary'
import { RecentlyViewedCarouselCompleteRemote } from '@spoonflower/recently-viewed-carousel/complete-remote'
import {
  SUPERGRAPH_BASE_URL,
  RECENTLY_VIEWED_REMOTE_ACCESS_URL,
  PDP_BASE_URL,
  PROFILES_BASE_URL,
  RECENTLY_VIEWED_BASE_URL,
  SPOONFLOWER_BASE_URL,
} from '@modules/app'

export type UserPreferences = {
  country: string
  language: string
  currency: string
}

export const CartPage = ({
  hasUserToken,
  user: serverUser,
}: {
  user?: User
  hasUserToken: boolean // result of isValidToken getServerSideProps
}) => {
  const { preferences } = useUserPreferences()
  const toast = useToast()
  const router = useRouter()
  const { query } = router
  const notAuthorizedOrderIdRedirect = query.order || ''

  const { data: user = serverUser, isFetched } = useWhoAmI() // getting the current user information

  const isGuest = isFetched && user?.guest

  const intl = useIntl()

  const title = intl.formatMessage({ id: 'cart.title' })
  const orderIdAuthError = intl.formatMessage({ id: 'orderId.authError' })
  const checkoutError = intl.formatMessage({
    id: 'account.register.error.somethingWentWrong',
  })

  const [isUpdatingCoupon, setIsUpdatingCoupon] = useState(false)

  const isBrowser = useIsBrowser()
  const { cart } = useCart({
    onCartItemUpdateError: (e) => toast({ status: 'error', ...getError(e) }),
    onCartItemDeleteError: (e) => toast({ status: 'error', ...getError(e) }),
  })
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: { areAllDesignsSalable },
    refetch: getDesignSalabilities,
  } = useDesignSalabilities(cart?.lineItems, cart?.id)
  const {
    data: { areAllProductsSalable },
    refetch: getProductStatuses,
  } = useProductStatuses(cart?.lineItems, cart?.id)
  const isPlusInCart =
    cart?.lineItems && isSubscriptionTypeProductInCart(cart.lineItems)
  const isPlusSingleItem =
    cart?.lineItems &&
    isSubscriptionTypeProductTheOnlyProductTypeInCart(cart.lineItems)

  async function checkout() {
    setIsLoading(true)

    try {
      const { data: designSalabilitiesData } = await getDesignSalabilities({
        throwOnError: true,
      })
      const { areAllDesignsSalable = false } = designSalabilitiesData ?? {}
      const { data: productStatusesData } = await getProductStatuses({
        throwOnError: true,
      })
      const { areAllProductsSalable = false } = productStatusesData ?? {}

      if (!areAllDesignsSalable || !areAllProductsSalable) {
        toast({
          duration: null,
          status: 'error',
          title: intl.formatMessage({
            id: 'checkout.validation.someItemsNoLongerAvailable',
          }),
        })

        setIsLoading(false)

        return
      }

      if (isPlusInCart) {
        let plusErrorMessage

        if (!isPlusSingleItem) {
          plusErrorMessage = 'checkout.validation.plusIsNotSingleItem'
        } else if (user?.plus?.isActive) {
          plusErrorMessage = 'checkout.validation.plusAlreadyActive'
        }

        if (plusErrorMessage) {
          toast({
            description: intl.formatMessage({
              id: plusErrorMessage,
            }),
            duration: 10000,
            isClosable: false,
            status: 'info',
          })

          setIsLoading(false)

          return
        }
      }

      await router.push(PATHS.CHECKOUT)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      toast({
        status: 'error',
        title: checkoutError,
      })
    }
  }

  useEffect(() => {
    if (notAuthorizedOrderIdRedirect.length) {
      // The user was redirected from the success page because the order ID was not authorized
      toast({
        status: 'error',
        title: orderIdAuthError,
      })
    }
  }, [notAuthorizedOrderIdRedirect])

  useEffect(() => {
    // TODO: Remove this once we have a proper solution for /en redirect
    if (history.state.as === '/en' && preferences?.locale !== 'en') {
      history.replaceState(
        {
          ...history.state,
          as: '/',
          url: '/',
        },
        '',
        '/'
      )
    }
  }, [])

  if (!isBrowser) {
    return null
  }

  return (
    <Container
      backgroundColor={{ xs: '#FAFAFA', md: 'warm-white' }}
      maxWidth="full"
      paddingX="0"
    >
      <NextSeo title={title} noindex nofollow />

      {cart.isLoading && hasUserToken ? (
        <Container maxWidth="container.3xl" paddingX={{ base: '0', xl: '16' }}>
          <CartLoadingState />
        </Container>
      ) : cart.isEmpty || !hasUserToken ? (
        <CartEmptyState />
      ) : (
        <Container maxWidth="container.3xl" paddingX={{ base: '0', xl: '16' }}>
          <Container
            backgroundColor={{ xs: 'warm-white', md: 'transparent' }}
            maxWidth="full"
            overflow="hidden"
            paddingX={{ base: '6', xl: '0' }}
            position="relative"
            zIndex="1"
          >
            <Heading
              as="h2"
              marginBottom={{ base: '0', md: '6', lg: '12', '2xl': '4.5rem' }}
              marginTop="10"
              size={{ base: 'lg', md: '2xl' }}
              variant="serif"
            >
              <Text
                as="span"
                color="primary-text"
                marginRight="3"
                textStyle="title-case"
              >
                {title}
              </Text>

              <Text as="span" color="tertiary-text">
                {`(${intl.formatMessage(
                  { id: 'cart.titleCount' },
                  { count: cart.lineItems?.length }
                )})`}
              </Text>
            </Heading>
          </Container>

          <Flex direction={{ base: 'column', xl: 'row' }}>
            <Container
              maxWidth="full"
              paddingX={{ base: '0' }}
              paddingBottom={{ base: '0', md: '24' }}
              backgroundColor={{ base: '#FAFAFA', xl: 'warm-white' }}
              sx={{
                '.keen-slider ~ .chakra-stack': {
                  backgroundColor: { base: '#FAFAFA', xl: 'warm-white' },
                },
              }}
            >
              <CartItemList />
            </Container>

            <Flex
              as={'aside'}
              justify={'flex-end'}
              marginLeft={{ base: '0', xl: '4rem' }}
              marginTop={{ base: '8', md: '0' }}
              maxWidth={{ base: 'full', xl: '30.5rem' }}
              width={{ base: 'full' }}
              height={'fit-content'}
            >
              <CartSummary
                isUpdatingCoupon={isUpdatingCoupon}
                setIsUpdatingCoupon={setIsUpdatingCoupon}
                showPaymentMethods={false}
                buttonProps={{
                  buttonText: intl.formatMessage({ id: 'action.checkout' }),
                  onSubmit: checkout,
                  isDisabled:
                    !areAllDesignsSalable ||
                    !areAllProductsSalable ||
                    isLoading,
                  isLoading: isLoading,
                }}
                isCartPage
              />
            </Flex>
          </Flex>
          <RecentlyViewedCarouselCompleteRemote
            url={RECENTLY_VIEWED_REMOTE_ACCESS_URL}
            domNode={document.body}
            designDataLoaderUrl={SUPERGRAPH_BASE_URL}
            pdpBaseUrl={PDP_BASE_URL}
            profilesBaseUrl={PROFILES_BASE_URL}
            recentlyViewedBaseUrl={RECENTLY_VIEWED_BASE_URL}
            spoonflowerBaseUrl={SPOONFLOWER_BASE_URL}
            styles={{
              px: { base: 6, xl: 0 },
              pt: 8,
              pb: 24,
              '.rvc-heading-container > h2.chakra-heading': {
                xs: {
                  fontSize: '1.5rem',
                },
                md: {
                  fontSize: '2.25rem',
                },
              },
            }}
          />
        </Container>
      )}
    </Container>
  )
}
